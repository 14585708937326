"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  strings: {
    // Shown in the Status Bar
    compressingImages: 'Compressing images...',
    compressedX: 'Saved %{size} by compressing images'
  }
};
exports.default = _default;